<template>
    <div ref="el">
        <slot />
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

@Component({})
export default class BlogNext extends Vue {
    @Prop({ required: true }) link: string;
    @Ref('el') el: HTMLElement;
    @Ref('pin') pin: HTMLElement;

    progress = 0;
    userInteracted = false;
    scrollTimeout: number | null = null;
    pageLoaded = false;

    created() {
        gsap.registerPlugin(ScrollTrigger);
    }

    mounted() {
        // to know if the page is fully loaded
        window.onload = this.setPageLoaded;

        // to know if is a user or script
        ScrollTrigger.normalizeScroll(true);
        this.addUserInteractionListeners();

        gsap.timeline({
            ease: 'none',
            scrollTrigger: {
                trigger: this.el,
                start: 'bottom bottom',
                end: '+=150%',
                scrub: 0.2,
                pin: this.el
            },
            onComplete: this.loadNextBlog
        })
            .add(gsap.fromTo(this.el, { clipPath: 'inset(0% 90% 0% 0%)' }, { clipPath: 'inset(0% 0% 0% 0%)' }));
    }

    loadNextBlog() {
        // change page if is a user and the page is completly loaded
        if (this.userInteracted && this.pageLoaded) {
            // add page to the history's browser (so person can return back)
            history.pushState({ path: this.link }, '', this.link);

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'blog-endless-mode',
                term: this.link
            });
            window.location.href = this.link;
        } else {
            console.warn('Page not loaded or user not detected');
        }
    }

    /*
     * User detect
     */
    addUserInteractionListeners() {
        window.addEventListener('click', this.setUserInteracted);
        window.addEventListener('mousemove', this.setUserInteracted);
        window.addEventListener('touchstart', this.setUserInteracted);
        window.addEventListener('scroll', this.detectUserScroll);
    }

    setUserInteracted() {
        this.userInteracted = true;
        window.removeEventListener('click', this.setUserInteracted);
        window.removeEventListener('mousemove', this.setUserInteracted);
        window.removeEventListener('touchstart', this.setUserInteracted);
        window.removeEventListener('scroll', this.detectUserScroll);
    }

    detectUserScroll() {
        if (this.scrollTimeout) {
            clearTimeout(this.scrollTimeout);
        }

        this.scrollTimeout = window.setTimeout(() => {
            this.setUserInteracted();
        }, 100);
    }

    setPageLoaded() {
        this.pageLoaded = true;
    }
}
</script>
