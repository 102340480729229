/* eslint-disable quote-props */
const plugin = require('tailwindcss/plugin');

module.exports = {
    content: [
        './templates/**/*.ftl',
        './includes/templates/*.ftl',
        './macros/*.ftl',
        './libraries/*.ftl',
        './ts/**/*.vue'
    ],
    theme: {
        extend: {
            animation: {
                'ticker-a': 'ticker-a 10s linear -10s infinite',
                'ticker-a-reverse': 'ticker-a 10s linear -10s reverse infinite',
                'ticker-b': 'ticker-b 10s linear -5s infinite',
                'ticker-b-reverse': 'ticker-b 10s linear -5s reverse infinite',
                'from-top': 'from-top 0.8s ease-out forwards',
                'from-bottom': 'from-bottom 0.4s ease-out forwards',
                'spin-slow': 'spin 20s linear infinite',
                'load': 'load 1s linear infinite'
            },
            backgroundOpacity: {
                '65': '0.65', '85': '0.85'
            },
            borderWidth: {
                '3': '3px',
                '5': '5px',
                '6': '6px',
                '76': '19rem'
            },
            inset: {
                '10/3': '30%',
                '1/5': '20%',
                '1/6': '16.66%',
                '1/12': '8.33%'
            },
            container: {
                center: true,
                padding: {
                    DEFAULT: '2.25rem',
                    lg: '4rem',
                    xl: '5rem',
                    '2xl': '10rem'
                }
            },
            colors: {
                current: 'currentColor',
                berry: {
                    DEFAULT: '#EC628B',
                    500: '#EC628B', // raspberry || not used?
                    600: '#B02A87', // fresh-berry
                    700: '#972875' // dark-fresh-berry
                },
                gray: {
                    50: '#FDFDFD', // very-light-gray
                    100: '#F8F8F8', // light-gray
                    200: '#F1F1F1', // medium-light-gray
                    300: '#b3b3b3', // white-70
                    400: '#999999', // medium-gray
                    500: '#606060',
                    600: '#707070', // gray-70
                    800: '#1F1F1F' // dark-gray
                },
                black: {
                    DEFAULT: '#111111', // black-transparent -> black/94
                    0: '#000000'
                },
                white: '#FFFFFF', // gray50 --> white/50 || white-transparent -> white/90
                blue: {
                    DEFAULT: '#5496cf',
                    900: '#213E7F', // primary
                    700: '#108ACF', // gradient blue
                    600: '#0097D9', // light-blue
                    500: '#5496cf', // blue
                    400: '#0098B0' // turquoise
                },
                orange: {
                    DEFAULT: '#EC6601',
                    500: '#EC6601', // orange
                    300: '#fac18a' // light-orange
                },
                pink: {
                    DEFAULT: '#eb618b',
                    700: '#A12F8C', // dark-pink
                    500: '#eb618b', // pink
                    300: '#f5b3c4' // light-pink
                },
                'purple': '#575DAE',
                'success-green': '#339c3a',
                'error-red': '#cc1000'
            },
            fontFamily: {
                sans: ['ProximaNova'],
                marketpro: ['MarketPro']
            },
            backgroundImage: () => ({
                'blog': 'url(\'../assets/img/Blog-pattern-white-both-sides.png\')',
                'blog-contrast': 'url(\'../assets/img/Blog-pattern-black-both-sides.png\')',
                'blog-left': 'url(\'../assets/img/Blog-pattern-white-left.png\')',
                'blog-left-contrast': 'url(\'../assets/img/Blog-pattern-black-left.png\')',
                'blog-right': 'url(\'../assets/img/Blog-pattern-white-right.png\')',
                'blog-right-contrast': 'url(\'../assets/img/Blog-pattern-black-right.png\')',
                'blog-supercorner-top': 'url(\'../assets/img/Blog-supercorner-white-top-left.png\')',
                'blog-supercorner-top-contrast': 'url(\'../assets/img/Blog-supercorner-black-top-left.png\')',
                'blog-supercorner-bottom': 'url(\'../assets/img/Blog-supercorner-white-bottom-right.png\')',
                'blog-supercorner-bottom-contrast': 'url(\'../assets/img/Blog-supercorner-black-bottom-right.png\')',
                'angle-light': 'url(\'../assets/img/bg-angle-white.svg\')',
                'angle-dark': 'url(\'../assets/img/bg-angle-dark.svg\')',
                'angle-hero': 'url(\'../assets/img/bg-angle-hero.svg\')',
                'angle-hero2': 'url(\'../assets/img/winkel-teaser-dark.svg\')',
                'angle-hero2-contrast': 'url(\'../assets/img/bg-angle-hero2-contrast.svg\')',
                'angle-hero-dark': 'url(\'../assets/img/bg-angle-hero-dark.svg\')',
                'angle-hero-dark2': 'url(\'../assets/img/winkel-teaser-hell.svg\')',
                'angle-stage-dark': 'url(\'../assets/img/winkel-stage-dark.svg\')',
                'angle-stage-very-dark': 'url(\'../assets/img/winkel-stage-very-dark.svg\')',
                'angle-stage-light': 'url(\'../assets/img/winkel-stage-light.svg\')',
                'double-angle': 'url(\'../assets/img/arrows-infobox.svg\')',
                'pattern': 'url(\'../assets/img/pattern_overlay.svg\')',
                'arrow-basic': 'url(\'../assets/img/header-basic.svg\')',
                'arrow-basic-light': 'url(\'../assets/img/header-basic-light.svg\')',
                'branding': 'url(\'../assets/img/bg-branding.svg\')',
                'contact': 'url(\'../assets/img/contact-bg.svg\')',
                'contact-blur': 'url(\'../assets/img/contact-blur.webp\')',
                'contact-angle': 'url(\'../assets/img/contact-angle.svg\')',
                'contact-angle-100': 'url(\'../assets/img/contact-angle-opacity100.svg\')',
                'contact-angle-transparent': 'url(\'../assets/img/contact-angle-transparent.svg\')',
                'arrow': 'url(\'../assets/img/GFT-Pfeil.svg\')',
                'arrow-light': 'url(\'../assets/img/gft-pfeil-light.svg\')',
                'download-berry': 'url(\'../assets/img/download-fresh-berry.svg\')',
                'download-white': 'url(\'../assets/img/download-white.svg\')',
                'footer-gradient': 'linear-gradient(90deg, rgba(123,71,148,1) 0%, rgba(19,151,183,1) 15%, rgba(32,61,126,1) 75%, rgba(14,51,103,1) 100%);',
                'homepage': 'linear-gradient(180deg, rgba(17,17,17, 0) 0%, rgba(17,17,17,0.276) 74%, #111111 100%)',
                'quote': 'url(\'../assets/img/quote.svg\')',
                'gft-angle': 'url(\'../assets/img/gft-angle.svg\')',
                'gft-angle-white': 'url(\'../assets/img/gft-angle-white.svg\')',
                'black-bubble': 'url(\'../assets/img/black-bubble-background.jpg\')'
            }),
            fontSize: {
                'sm': ['0.875rem', '1.25rem'],
                '2xl': ['1.5rem', '1.75rem'],
                '3xl': ['2rem', '2.5rem'],
                '4xl': ['2.5rem', '3rem'],
                '5xl': ['3rem', '3.3rem'],
                '6xl': ['4rem', '4.3rem'],
                '7xl': ['4.5rem', '5.5rem'],
                '8xl': ['6rem', '6.7rem'],
                '9xl': ['8.5vh', '9.4vh'], // subtle text/media slider heading
                '10xl': ['9rem', '9.9rem'],
                'h4': '1.5625rem', // 25px
                'h3': '2rem', // 32px
                'h2': '3rem', // 48px
                '50': ['3.125rem', '3.4rem'] // 50px
            },
            rotate: {
                '135': '135deg'
            },
            lineHeight: {
                'extra-tight': 0.71,
                'tighter': 0.9,
                '20': '5rem'
            },
            listStyleType: {
                square: 'square'
            },
            keyframes: {
                'load': {
                    '0%': { perspective: '12.5rem', transform: 'rotateX(0) rotateY(0)' },
                    '50%': { perspective: '12.5rem', transform: 'rotateX(-180deg) rotateY(0)' },
                    '100%': { perspective: '12.5rem', transform: 'rotateX(-180deg) rotateY(-180deg)' }
                },
                'ticker-a': {
                    '0%': {
                        transform: 'translateX(100%)'
                    },
                    '100%': {
                        transform: 'translateX(-100%)'
                    }
                },
                'ticker-b': {
                    '0%': {
                        transform: 'translateX(0%)'
                    },
                    '100%': {
                        transform: 'translateX(-200%)'
                    }
                },
                'from-top': {
                    '0%': {
                        transform: 'translateY(-50px)', opacity: 0
                    },
                    '100%': {
                        transform: 'translateY(0)', opacity: 1
                    }
                },
                'from-bottom': {
                    '0%': {
                        transform: 'translateY(100px)', opacity: 0
                    },
                    '100%': {
                        transform: 'translateY(0)', opacity: 1
                    }
                }
            },
            screens: {
                'xl': '1281px',
                'hmax': { 'raw': '(max-height: 680px) and (min-width: 1024px)' },
                'landscape': { 'raw': '(orientation: landscape) and (max-height: 400px)' },
                'hmd': { 'raw': '(min-height: 720px) and (min-width: 1024px)' },
                'hlg': { 'raw': '(min-height: 840px) and (min-width: 1024px)' }
            },
            spacing: {
                '18': '4.5rem',
                '112': '28rem',
                '125': '31.25rem',
                '128': '32rem',
                '150': '37.5rem',
                '160': '40rem',
                '1/6': '16.66%',
                '1/4': '25%',
                '1/2': '50%',
                '2/3': '66.66%',
                '3/4': '75%',
                '4/5': '80%'
            },
            height: {
                'screen-80': '80vh',
                'screen-90': '90vh',
                'pattern-mobile': '20%'
            },
            minHeight: {
                'screen-50': '50vh',
                'screen-80': '80vh',
                'screen-90': '90vh'
            },
            maxHeight: {
                'available': '-webkit-fill-available'
            },
            width: {
                'slide-1/4': 'calc(25% - 24px)',
                'slide-1/3': 'calc(33.33% - var(--gap) / 3 * 2)',
                'slide-1/2': 'calc(50% - 8px)',
                'pattern-mobile': '70%'
            },
            maxWidth: {
                'accordion': 'calc(100% - 2.25rem)',
                '150': '37.5rem'
            },
            stroke: theme => ({
                ...theme('colors')
            }),
            strokeWidth: {
                '0.5': 0.5
            },
            translate: {
                'double-full': '200%', // used like: -translate-x-double-full
                '3/20': '15%'
            },
            margin: {
                'sb': '-7px', // firefox scrollbar
                '1/2': '50%',
                '1/12': '8.33%'
            },
            padding: { // for image aspect ratio boxes
                '4/1': '25%',
                '3/1': '33.33%',
                '10/3': '30%',
                '1/2': '50%',
                '16/9': '56.25%',
                '5/3': '60%',
                'full': '100%',
                '5/6': '120%',
                '5/9': '180%'
            },
            gridTemplateColumns: {
                '2to1': '2fr 1fr'
            },
            gridTemplateRows: {
                '28-auto': '7rem auto'
            },
            typography: theme => ({
                DEFAULT: {
                    css: {
                        color: 'currentColor',
                        ul: {
                            paddingLeft: '1.25rem'
                        },
                        'ul > li::before': {
                            backgroundColor: 'currentColor',
                            borderRadius: 'none',
                            width: '0.3rem',
                            height: '0.3rem'
                        }
                    }
                },
                'link': {
                    css: {
                        a: {
                            color: theme('colors.berry.600')
                        }
                    }
                },
                'link-underline': {
                    css: {
                        a: {
                            fontWeight: '700',
                            color: theme('colors.berry.600'),
                            textDecoration: 'underline',
                            textDecorationThickness: '0.125rem',
                            textUnderlineOffset: '0.125rem'
                        }
                    }
                },
                'link-underline-contrast': {
                    css: {
                        a: {
                            fontWeight: '700',
                            color: 'white',
                            textDecoration: 'underline',
                            textDecorationThickness: '0.125rem',
                            textDecorationColor: theme('colors.berry.600'),
                            textUnderlineOffset: '0.125rem'
                        },
                        'a:hover': {
                            color: theme('colors.gray.200'),
                            textDecorationColor: theme('colors.berry.700')
                        }
                    }
                },
                'hero': {
                    css: {
                        fontSize: theme('fontSize.2xl')[0],
                        lineHeight: theme('fontSize.2xl')[1]
                    }
                },
                'hero-lg': {
                    css: {
                        color: theme('colors.gray.300'),
                        fontSize: theme('fontSize.4xl')[0],
                        lineHeight: theme('fontSize.4xl')[1]
                    }
                },
                'hero-default': {
                    css: {
                        'b, strong': {
                            color: theme('colors.black'),
                            fontWeight: 'normal'
                        }
                    }
                },
                'hero-contrast': {
                    css: {
                        'b, strong': {
                            color: theme('colors.white'),
                            fontWeight: 'normal'
                        }
                    }
                },
                'footnote': {
                    css: {
                        fontSize: theme('fontSize.xs')[0],
                        lineHeight: '1.125rem',
                        color: 'rgba(17, 17, 17, 0.7)',
                        'b, strong': {
                            color: 'rgba(17, 17, 17, 0.5)'
                        },
                        a: {
                            color: theme('colors.berry.600'),
                            textDecoration: 'none'
                        },
                        'a:hover': {
                            color: theme('colors.berry.700'),
                            textDecorationColor: theme('colors.berry.700')
                        }
                    }
                }
            }),
            zIndex: {
                '-1': '-1'
            }
        },
        fontFeatureSettings: {
            marketpro: '"ss08"'
        },
        textIndent: () => ({
            list: '-1.375rem'
        }),
        underline: {
            '1': '.125rem',
            '2': '.25rem'
        }
    },
    plugins: [
        require('@tailwindcss/typography'),
        require('@tailwindcss/line-clamp'),
        plugin(function({ addVariant, e }) {
            addVariant('before', ({ modifySelectors, separator }) => {
                modifySelectors(({ className }) => {
                    return `.${e(`before${separator}${className}`)}::before`;
                });
            });
            addVariant('after', ({ modifySelectors, separator }) => {
                modifySelectors(({ className }) => {
                    return `.${e(`after${separator}${className}`)}::after`;
                });
            });
            // add custom variant to style content of active slides (parent has .slide-active)
            addVariant('active-slide', ({ modifySelectors, separator }) => {
                modifySelectors(({ className }) => {
                    return `.slide-active .${e(`active-slide${separator}${className}`)}`;
                });
            });
            // add variant to make utilities use !important
            addVariant('important', ({ container }) => {
                container.walkRules(rule => {
                    rule.selector = `.\\!${rule.selector.slice(1)}`;
                    rule.walkDecls(decl => {
                        decl.important = true;
                    });
                });
            });
        }), // add custom moz: variant to target firefox only
        plugin(function({ addVariant, e }) {
            addVariant('firefox', ({ container, separator }) => {
                container.walkRules(rule => {
                    rule.selector = `@-moz-document url-prefix() { .${e(`firefox${separator}${rule.selector.slice(1)}`)}`;
                    rule.each(i => {
                        i.selector = `.${e(`firefox${separator}${i.selector.slice(1)}`)}`;
                    });
                    rule.selector += ' }';
                });
            });
        }),

        plugin(function({ addUtilities }) {
            const newUtilities = {
                '.content': { content: 'attr(data-content)' },
                '.content-before': { content: 'attr(data-before)' },
                '.content-after': { content: 'attr(data-after)' }
            };
            addUtilities(newUtilities, ['before', 'after']);
        })
    ]
};
