<template>
    <div ref="el" class="relative" :style="`--gap: ${masonryGutter}px`">
        <div>
            <slot name="filter-rendering"
                  :filters="filters"
                  :toggle-category="toggleCategory"
                  :is-selected="isSelected"
                  :set-date-filter="setDateFilter"
                  :clear-date-filter="clearDateFilter"
                  :date-filter="dateFilter" />
            <slot name="filter-registration" />
        </div>
        <slot v-if="items.length" :items="items"
              :other-items="otherItems"
              :get-bg-color="getBgColor"
              :total="result ? result.total : 0"
              :hide-cursor="hideCursor"
              :show-cursor="showCursor"
              :remove-category="removeCategory"
              :selected-categories="selectedCategories"
              :load-more="loadMore" />
        <div v-if="loading" class="flex my-8">
            <!-- spinner -->
            <span class="mx-auto h-12 w-12 relative animate-load" :class="contrast ? 'bg-white' : 'bg-black'"></span>
        </div>
        <slot name="cursor"
              v-if="!sophos"
              :pos="{ left: `${cursor.x}px`, top: `${cursor.y}px` }"
              :show="showCustomCursor">
            <div :class="showCustomCursor ? 'absolute flex cursor-none' : 'hidden'"
                 :style="{ left: `${cursor.x}px`, top: `${cursor.y}px` }"
                 class="pointer-events-none z-30 h-16 w-16 -mt-8 -ml-8 items-center justify-center rounded-full bg-black/[0.94] border border-white/90">
                <icon name="plus" class="h-6 w-6 fill-current text-white" />
            </div>
        </slot>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Ref, Watch, Provide } from 'vue-property-decorator';
import { VNode } from 'vue';
import AsyncOverviewFilter from './AsyncOverviewFilter.vue';
import Icon from '../base/Icon.vue';
import { addToState, removeFromState } from '../utils';
import Masonry from 'masonry-layout';

const URL = '/.rest/api/v1/overview';
const LIMIT = 12;

interface Result {
    results: ResultItem[];
    total: number;
    offset: number;
    limit: number;
}

export interface ResultItem {
    id: string;
    slug: string;
    link: string;
    link_target: string;
    date: string;
    kicker: string;
    title: string;
    text: string;
    image: string;
    imageAlt?: string;
    layout: string;
    style: string;
    showFilter?: boolean;
    flags?: string[];
    author?: {
        name: string;
        image?: string;
        imageAlt?: string;
    };
    categories: {
        name: string;
        label: string;
        parent: string;
    }[];
    links?: {
        url: string;
        label: string;
        type: string;
    }[];
    media?: {
        type: string;
        vimeoLink?: string;
        youtubeId?: string;
        image?: string;
        imageAlt?: string;
        thumbnail?: string;
        thumbnailAlt?: string;
        full?: string;
    }[];
    infotitle?: string;
    infotext?: string;
    isEvent?: boolean;
    isNews?: boolean;
    isService?: boolean;
    country?: string;
}

@Component({
    components: { Icon }
})
export default class AsyncOverview extends Vue {
    @Prop({ required: true }) uuid: string;
    @Prop({ required: true }) workspace: string;
    @Prop({ default: false, type: Boolean }) masonry: boolean;
    @Prop({ default: false, type: Boolean }) includeUnknown: boolean;
    @Prop({ default: 16 }) masonryGutter: number;
    @Prop({ default: LIMIT }) limit: number;
    @Prop({ default: false, type: Boolean }) contrast: boolean;
    @Prop({ default: true, type: Boolean }) autoLoadMore: boolean;
    @Prop({ default: false, type: Boolean }) loadOtherItems: boolean;
    @Prop({ default: false, type: Boolean }) subtle: boolean;
    @Prop({ default: false, type: Boolean }) sophos: boolean;

    @Ref('el') el: HTMLElement;

    items: ResultItem[] = [];
    categories: { [id: string]: number };
    otherItems: ResultItem[] = [];
    offset = 0;
    dateFilter = 0;
    loading = false;
    controller = new AbortController();
    msnry: Masonry = null;
    itemsElements: HTMLElement[] = [];
    msnryOther: Masonry = null;
    itemsElementsOther: HTMLElement[] = [];
    error = false;
    endReached = false;
    filters: InstanceType<typeof AsyncOverviewFilter>[] = [];

    selectedFilters: string[][] = [];
    result: Result = null;
    othersResult: Result = null;
    cursor = { x: 0, y: 0 };
    showCustomCursor = false;
    // used to provide variables to children which will change over time
    // because primitive values are not reactive through provide
    reactiveOverviewVars: {
        categoriesUrl: string;
        selectedCategories: string[];
        unknownFilters?: {
            id: string;
            name: string;
            label: string;
            categories: { id: string; name: string; label: string }[];
        }[];
    } = {
        categoriesUrl: '',
        selectedCategories: [],
        unknownFilters: []
    };

    @Provide('registerOverviewFilter') provideRegisterFilter = this.registerFilter;
    @Provide('toggleCategory') provideToggleCategory = this.toggleCategory;
    @Provide('isSelected') provideIsSelected = this.isSelected;
    @Provide('reactiveOverviewVars') provideReactiveOverviewVars = this.reactiveOverviewVars;
    @Provide('showCursor') provideShowCursor = this.showCursor;
    @Provide('hideCursor') provideHideCursor = this.hideCursor;
    @Provide('scrollToTop') provideScrollToTop = this.scrollToTop;

    mounted() {
        document.addEventListener('scroll', this.onScroll);
        window.addEventListener('mousemove', this.onMouseMove);
        window.addEventListener('hashchange', this.changedHash);
        if (!this.$slots['filter-registration']) {
            this.loadHash();
        }
    }

    beforeUnmount() {
        document.removeEventListener('scroll', this.onScroll);
        window.removeEventListener('mousemove', this.onMouseMove);
    }

    async getItems(append = false, cancel = true) {
        if (!append) {
            this.offset = 0;
        }
        if (this.loading && cancel) {
            this.controller.abort();
            this.loading = false;
            this.controller = new AbortController();
        }
        try {
            if (!this.workspace.length) {
                console.error('workspace missing');
                throw new Error();
            }
            this.loading = true;
            this.error = false;
            let url = `${this.$contextPath}${URL}/${this.$site}/${this.$lang}/${this.workspace}/${this.uuid}`;
            if (this.autoLoadMore) {
                // FIXME: manually loading more items in a slider jumps so we just load everything at once
                url += `?limit=${this.limit}&offset=${this.offset}`;
            }
            if (this.filterString.length) {
                url += `${this.autoLoadMore ? '&' : '?'}filter=${this.filterString}`;
            }
            if (this.dateFilter !== 0) {
                url += `${url.includes('?') ? '&' : '?'}date=${this.dateFilter}`;
            }
            const response = await fetch(url, { signal: this.controller.signal });
            if (response.status === 200) {
                this.result = (await response.json()) as Result;
                if (append) {
                    this.items.push(...this.result.results);
                } else {
                    this.items = this.result.results;
                }
            } else {
                this.result = null;
                this.items = [];
            }
            if (this.loadOtherItems && !append && this.selectedFilters.reduce((a, c) => a + c.length, 0) > 0) {
                let otherUrl = `${this.$contextPath}${URL}/other/${this.$site}/${this.$lang}/${this.workspace}/${this.uuid}?limit=6&offset=0`;
                if (this.filterString.length) {
                    otherUrl += `&filter=${this.filterString}`;
                }
                const otherResponse = await fetch(otherUrl, { signal: this.controller.signal });
                if (otherResponse.status === 200) {
                    this.othersResult = (await otherResponse.json()) as Result;
                    this.otherItems = this.othersResult.results;
                }
            } else {
                this.othersResult = null;
                this.otherItems = [];
            }
        } catch (e) {
            this.error = true;
        }
        this.loading = false;

        if (this.masonry) {
            this.layoutMasonry();
            if ((this.loadOtherItems && !append && this.selectedFilters.reduce((a, c) => a + c.length, 0) > 0) || this.msnryOther) {
                this.layoutOtherMasonry();
            }
        }
    }

    registerFilter(filter: InstanceType<typeof AsyncOverviewFilter>) {
        this.filters.push(filter);
        this.selectedFilters.push([]);
        if (this.$slots['filter-registration'].filter(this.filterItems).length === this.filters.length) {
            this.loadHash();
        }
    }

    async toggleCategory(key: string, parent = '', remove = false, updateHash = true, fetch = true) {
        const selFilters = this.selectedFilters;
        const filter = this.filters.find(f => f.categories.some(c => c.categoryKey === key));
        if (!filter) {
            if (this.includeUnknown) {
                // check if its already set and needs to be removed or if it has to be added
                if (this.reactiveOverviewVars.unknownFilters.some(f => f.categories.some(c => c.name === key))) {
                    this.removeUnknownFilter(key);
                } else if (parent) {
                    await this.addUnknownFilters([`${parent}:${key}`], updateHash);
                }
                if (fetch) {
                    await this.getItems(false);
                    this.scrollToTop();
                }
                return;
            }
        }
        const filterIndex = this.filters.findIndex(x => x.filterKey === filter.filterKey);
        const index = selFilters[filterIndex].indexOf(key);
        if (index === -1 && !remove) {
            if (updateHash) {
                addToState(filter.filterKey, key);
            }
            selFilters[filterIndex].push(key);
        } else {
            if (updateHash) {
                removeFromState(filter.filterKey, key);
            }
            selFilters[filterIndex].splice(index, 1);
        }
        if (fetch) {
            await this.getItems(false);
        }
    }

    removeCategory(key: string) {
        if (this.selectedCategories.map(x => x.key).includes(key)) {
            this.toggleCategory(key);
        }
    }

    removeUnknownFilter(key: string) {
        const index = this.reactiveOverviewVars.unknownFilters.findIndex(x => x.categories.map(c => c.name).includes(key));
        if (index >= 0) {
            // get the filter key (parent) now because the parent might get deleted, and we need it to remove it from the hash
            const filterKey = this.reactiveOverviewVars.unknownFilters[index].name;
            // remove the key from its parents categories
            this.reactiveOverviewVars.unknownFilters[index].categories.splice(
                this.reactiveOverviewVars.unknownFilters[index].categories.findIndex(x => x.name === key), 1
            );
            // if now the filter has no more categories, remove the parent as well
            if (this.reactiveOverviewVars.unknownFilters[index].categories.length === 0) {
                this.reactiveOverviewVars.unknownFilters.splice(index, 1);
            }
            // finally also remove it from the hash and fetch the items again
            removeFromState(filterKey, key);
        }
    }

    async addUnknownFilters(unknownFilters: string[], updateHash = false) {
        const response = await fetch(`${this.$contextPath}${URL}/filters/${this.$lang}`, {
            method: 'post',
            body: JSON.stringify({ filters: unknownFilters }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (response.ok) {
            this.reactiveOverviewVars.unknownFilters.push(...await response.json());
            if (updateHash) {
                unknownFilters.forEach(x => {
                    const [key, value] = x.split(':');
                    addToState(key, value);
                });
            }
        }
    }

    filterItems(child: VNode) {
        return child && child.componentOptions && child.componentOptions.tag === 'async-overview-filter';
    }

    onScroll() {
        if (this.el.getBoundingClientRect().bottom - window.innerHeight < 0) {
            if (!this.endReached) {
                this.endReached = true;
            }
        } else {
            if (this.endReached) {
                this.endReached = false;
            }
        }
    }

    scrollToTop() {
        this.$smoothScroll({ scrollTo: this.el, updateHistory: false });
    }

    onMouseMove(event: MouseEvent) {
        this.cursor.x = event.clientX - this.el.getBoundingClientRect().left;
        this.cursor.y = event.clientY - this.el.getBoundingClientRect().top;
    }

    isSelected(key: string): boolean {
        return this.selectedFilters.some(f =>
            f.some((x: string) => x === key)
        ) || this.reactiveOverviewVars.unknownFilters.some(f =>
            f.categories.some(x => x.name === key)
        );
    }

    layoutMasonry() {
        // if already exists
        if (this.msnry) {
            this.$nextTick(() => {
                // get all elements, new ones (to add), and old ones (to remove)
                const allItems = Array.from(this.el.querySelectorAll('.msnry-container .msnry-item')).map(item => item as HTMLElement);
                const newItems = allItems.filter(item => !this.itemsElements.includes(item));
                const oldItems = this.itemsElements.filter(item => !allItems.includes(item));

                // if removed all previous items, redo the mansory -> acts weird if not
                if (oldItems.length === this.itemsElements.length) {
                    this.itemsElements = [];
                    this.msnry.destroy();
                    this.msnry = null;
                    if (newItems.length > 0) {
                        this.createMansory();
                    }
                    return;
                }
                // update itemsElements
                this.itemsElements.push(...newItems);
                this.itemsElements = this.itemsElements.filter(item => !oldItems.includes(item));

                // updateMansory
                if (newItems.length > 0) {
                    this.msnry.appended(newItems);
                }
                if (oldItems.length > 0) {
                    this.msnry.remove(oldItems);
                    this.msnry.layout();
                }
            });
            return;
        }
        // create a new one
        this.createMansory();
    }

    createMansory() {
        // new Masonry
        this.$nextTick(() => {
            this.msnry = new Masonry(this.el.querySelector('.msnry-container'), {
                itemSelector: '.msnry-item',
                columnWidth: '.msnry-item',
                gutter: this.masonryGutter,
                percentPosition: true,
                horizontalOrder: true
            });
            this.itemsElements = Array.from(this.el.querySelectorAll('.msnry-container .msnry-item')).map(item => item as HTMLElement);
        });
    }

    layoutOtherMasonry() {
        if (this.msnryOther) {
            this.$nextTick(() => {
                // get all elements, new ones (to add), and old ones (to remove)
                const allItems = Array.from(this.el.querySelectorAll('.msnry-container-other .msnry-item')).map(item => item as HTMLElement);
                const newItems = allItems.filter(item => !this.itemsElementsOther.includes(item));
                const oldItems = this.itemsElementsOther.filter(item => !allItems.includes(item));

                // if removed all previous items, redo the mansory -> acts weird if not
                if (oldItems.length === this.itemsElementsOther.length) {
                    this.itemsElementsOther = [];
                    this.msnryOther.destroy();
                    this.msnryOther = null;
                    if (newItems.length > 0) {
                        this.createOtherMansory();
                    }
                    return;
                }
                // update itemsElements
                this.itemsElementsOther.push(...newItems);
                this.itemsElementsOther = this.itemsElementsOther.filter(item => !oldItems.includes(item));
                // updateMansory
                if (newItems.length > 0) {
                    this.msnryOther.appended(newItems);
                }
                if (oldItems.length > 0) {
                    this.msnryOther.remove(oldItems);
                    this.msnryOther.layout();
                }
            });
            return;
        }
        // create a new one
        this.createOtherMansory();
    }

    createOtherMansory() {
        this.$nextTick(() => {
            this.msnryOther = new Masonry(this.el.querySelector('.msnry-container-other'), {
                itemSelector: '.msnry-item',
                columnWidth: '.msnry-item',
                gutter: this.masonryGutter,
                percentPosition: true,
                horizontalOrder: true
            });
            this.itemsElementsOther = Array.from(this.el.querySelectorAll('.msnry-container-other .msnry-item')).map(item => item as HTMLElement);
        });
    }

    /**
     * filter by dates
     * @param value if negative, shows past items, positive shows future items. 0 applies no date filter
     */
    setDateFilter(value: number) {
        if (this.dateFilter !== 0) {
            removeFromState('date');
        }
        this.dateFilter = this.dateFilter === value ? 0 : value;
        const dateFilter = value < 0 ? 'past' : (value > 0 ? 'upcoming' : 'all');
        if (this.dateFilter !== 0) {
            addToState('date', dateFilter);
        }
        this.getItems();
    }

    clearDateFilter() {
        this.dateFilter = 0;
        removeFromState('date');
        this.getItems();
    }

    getBgColor(style = 'default', colors = {
        color: 'bg-blue-900 text-white',
        contrast: 'bg-black text-white',
        default: 'bg-gray-100',
        semi: 'bg-gray-400'
    }): string {
        if (this.sophos) {
            return 'rounded-lg shadow-lg';
        }
        if (this.subtle) {
            return '';
        }
        if (this.contrast) {
            return 'bg-white';
        }
        if (Object.prototype.hasOwnProperty.call(colors, style)) {
            return colors[style];
        }
        return colors.default;
    }

    async loadHash(update = false) {
        const hash = location.hash.replace(/^#/, '');
        const filters = hash
            .split('&')
            .filter(x => x.includes(':'))
            .map(x => x.split(':'))
            .filter(x => x.length === 2);

        const dateFilter = filters.find(x => x[0] === 'date');
        if (dateFilter) {
            if (dateFilter[1] === 'upcoming') {
                this.dateFilter = 1;
            }
            if (dateFilter[1] === 'past') {
                this.dateFilter = -1;
            }
        }
        // filters from the url that are not selectable
        const unspecifiedFilters = [];

        filters.filter(
            f =>
                this.filters
                    .findIndex(x => x.filterKey === f[0]) >= 0 || this.includeUnknown
        ).forEach((f: string[], i: number) => {
            const filterCategory = this.filters
                .find((x: InstanceType<typeof AsyncOverviewFilter>) => x.filterKey === f[0]);
            if (filterCategory) {
                f[1].split(',').forEach((key: string) => {
                    this.toggleCategory(key, '', false, update && i + 1 === filters.length, false);
                });
            } else {
                unspecifiedFilters.push(f.join(':'));
            }
        });
        // fetch unspecified filters
        if (unspecifiedFilters.length) {
            await this.addUnknownFilters(unspecifiedFilters);
        }
        return this.getItems(false);
    }

    loadMore() {
        // needs to be called manually if autoLoadMore is false (e.g.) for slider
        if (this.result && this.result.total > this.items.length && this.offset + this.limit <= this.result.total) {
            this.offset += this.limit;
            this.getItems(true, false);
        }
    }

    // cursor functions
    hideCursor() {
        if (!this.sophos) {
            this.showCustomCursor = true;
        }
    }

    showCursor() {
        if (!this.sophos) {
            this.showCustomCursor = false;
        }
    }

    @Watch('endReached')
    onEndReachedChanged() {
        if (this.endReached && this.autoLoadMore) {
            this.loadMore();
        }
    }

    // gets the keys and names of all selected categories
    get selectedCategories(): { key: string; name: string }[] {
        const cats = this.filters.reduce((a, x) => [...a, ...x.categories.map(y => ({
            key: y.categoryKey,
            name: y.name
        }))], []);
        return cats.filter(x => this.selectedFilters.reduce((a, x) => [...a, ...x], []).includes(x.key));
    }

    get filterString(): string {
        let fs = this.selectedFilters
            .filter(x => x.length)
            .join(',');
        if (this.includeUnknown && this.reactiveOverviewVars.unknownFilters.length) {
            fs += `&external=${this.reactiveOverviewVars.unknownFilters.map(f => f.categories.map(c => c.name)).join(',')}`;
        }
        return fs;
    }

    /**
     * Watchers
     */
    @Watch('selectedFilters')
    setCategoriesUrl() {
        let url = `${this.$contextPath}${URL}/filters/${this.$site}/${this.$lang}/${this.workspace}/${this.uuid}`;
        if (this.filterString.length) {
            url += `?filter=${this.filterString}`;
        }
        // set new value to make it reactive so children receive the change
        Vue.set(this.reactiveOverviewVars, 'categoriesUrl', url);
    }

    @Watch('selectedCategories')
    setSelectedCategories() {
        // set new value to make it reactive so children receive the change
        Vue.set(this.reactiveOverviewVars, 'selectedCategories', this.selectedCategories.map(x => x.key));
    }

    // used when the hash change without refresh the page (changed by navigation)
    async changedHash(event) {
        if (event.newURL.includes('caseopen=') || event.oldURL.includes('caseopen=')) {
            return;
        }
        this.selectedFilters.forEach(filter => {
            filter.forEach(item => {
                this.removeCategory(item);
            });
        });
        this.loadHash(true);
        await this.getItems(false);
    }
}
</script>
